<app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

<div class="row">
  <div class="card">
    <div class="card-title">
      <h5 class="card-title pt-2">
        <strong>
          {{ clientInfo.first_name }} {{ clientInfo.last_name }} - #{{
            clientInfo.record_id
          }}
        </strong>
      </h5>
      <p class="card-text fst-italic p2">
        <strong>Nombre del cliente:</strong> {{ clientInfo.first_name }}
        {{ clientInfo.last_name }} <br />
        <strong>Correo electr&oacute;nico:</strong> {{ clientInfo.email }}
        <br />
        <strong>Tel&eacute;fono:</strong> {{ clientInfo.phone }} <br />
        <strong>Fecha de nacimiento:</strong>
        {{ clientInfo.date_birthday }} <br />
        <strong>Codigo postal:</strong> {{ clientInfo.cp }}
      </p>
      <button class="btn btn-success" (click)="showPayments(clientInfo.id)">
        Informacion de pagos
      </button>
    </div>
  </div>

  <div
    class="alert alert-danger"
    role="alert"
    *ngIf="submittedAppt && message != ''"
  >
    {{ message }}
  </div>

  <ul class="nav nav-tabs mt-3">
    <li class="nav-item">
      <a href="#depilacion" data-bs-toggle="tab" class="nav-link active">
        Sesiones de depilaci&oacute;n
      </a>
    </li>
    <li>
      <a href="#valoracion" data-bs-toggle="tab" class="nav-link">
        Sesiones de valoraci&oacute;n
      </a>
    </li>
  </ul>

  <div class="tab-content">
    <div class="tab-pane container active" id="depilacion">
      <div class="row">
        <div
          class="card mt-3 col-md-4"
          *ngFor="let info of appointmentInfo; let idx = index"
        >
          <div class="card-header">Tratamiento {{ idx + 1 }}</div>
          <div class="card-body">
            <h5 class="card-title"></h5>
            <p class="card-text">
              <strong>Numero de sesiones:</strong>
              {{ info.appoinment.number_session }} <br />
              <strong>Costo total:</strong> {{ info.package.price | currency }}
              <br />
              <strong>Abono:</strong> {{ info.package.advance | currency }}
              <br />
              <strong>Pago restante:</strong>
              {{ info.package.remaining | currency }} <br />
              <strong>Sucursal:</strong> {{ info.office.name }} <br />
              <strong>Sesion:</strong>
              {{
                info.information
                  ? info.information.start
                  : "No hay sesion programada"
              }}
              <br />
              <button
                class="btn btn-primary mt-1"
                (click)="showSessionsInfo(info.appoinment.id, idx)"
                ngbTooltip="Informacion del paquete"
              >
                <i class="bi bi-card-list"></i>
              </button>
              <button
                *ngIf="!info.package.finished && info.information"
                [disabled]="
                  info.appoinment.number_session < 8 ||
                  info.appoinment.number_session !=
                    info.information.session_number
                "
                ngbTooltip="Dar de alta al cliente"
                class="btn btn-danger mt-1 ms-2"
                (click)="closeAppoinmentPackage(info.package.id)"
              >
                <i class="bi bi-card-checklist"></i>
              </button>
              <button
                class="btn btn-success mt-1 ms-2"
                ngbTooltip="Ver Contrato"
                (click)="displayContract(info.package.contract)"
              >
                <i class="bi bi-file-text-fill"></i>
              </button>
              <button
                class="btn btn-warning mt-1 ms-2"
                ngbTooltip="Agregar Pago"
                (click)="displayAddPayment(idx + 1, info.appoinment.id)"
              >
                <i class="bi bi-coin"></i>
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="tab-pane container fade" id="valoracion">
      <div class="row">
        <div
          class="card mt-3 col-md-4"
          *ngFor="let info of valorationsInfo; let idx = index"
        >
          <div class="card-header">Valoraci&oacute;n {{ idx + 1 }}</div>
          <div class="card-body">
            <h5 class="card-title"></h5>
            <p class="card-text">
              <strong>Fecha de inicio de valoraci&oacute;n:</strong>
              {{ info.session_start }} <br />
              <strong>Fecha de fin de valoraci&oacute;n:</strong>
              {{ info.session_end }}
              <br />
              <strong>Estado de sesion de valoraci&oacute;n:</strong>
              {{ info.session_close }} <br />
              <strong>Sucursal:</strong> {{ info.office.name }} <br />
              <strong>Cabina:</strong> {{ info.cabin.name }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #sessionsInfo let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Tratamiento {{ threatmentNumber }}</h5>
    <button class="close" type="button" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div>
        <strong>Costo total del servicio: </strong>
        {{ userInfo[0].package.price | currency }}
      </div>
      <div>
        <strong>Anticipo: </strong> {{ userInfo[0].package.advance | currency }}
      </div>
      <div>
        <strong>Saldo restante a cubrir: </strong>
        {{ userInfo[0].package.remaining | currency }}
      </div>
      <div>
        <strong>Numero de pagos para cubrir restantes:</strong>
        {{ userInfo[0].totalPays }}
      </div>
      <div>
        <strong>Fecha de los pagos:</strong>
        <div class="ms-3" *ngFor="let payments of userInfo[0].payments">
          <strong>Pago {{ payments.sessionNumber }}:</strong>
          {{ payments.sessionPayment | currency }}
        </div>
      </div>
      <div>
        <strong>Porcentaje de descuento: </strong>
        {{ userInfo[0].package.percentage }} %
      </div>
      <div>
        <strong>Fecha de compra:</strong>
        {{ userInfo[0].package.date_purchased }}
      </div>

      <div>
        <strong>Duraci&oacute;n: </strong> {{ userInfo[0].package.duration }}
      </div>
      <div>
        <strong>Total de sesiones: </strong>
        {{ userInfo[0].package.session_number }}
      </div>
    </div>
    <div
      class="mt-2 alert alert-danger"
      role="alert"
      *ngIf="!userInfo[0].sessions"
    >
      No hay informaci&oacute;n de sesiones agendadas.
    </div>
    <table class="table table-striped mt-2" *ngIf="userInfo[0].sessions">
      <thead class="table-dark">
        <tr>
          <th># Sesion</th>
          <th>Fecha de inicio</th>
          <th>Fecha de finalizaci&oacute;n</th>
          <th>Pago</th>
          <th>Asistio</th>
          <th>Comentarios</th>
        </tr>
      </thead>
      <tbody *ngFor="let session of userInfo[0].sessions">
        <tr>
          <td>{{ session.numberSession }}</td>
          <td>{{ session.date_start }}</td>
          <td>{{ session.date_end }}</td>
          <td>{{ session.payment_amount | currency }}</td>
          <td *ngIf="session.assisted == 2">Pendiente</td>
          <td *ngIf="session.assisted == 1">Asistio</td>
          <td *ngIf="session.assisted == 0">No asistio</td>
          <td>{{ session.comments }}</td>
        </tr>
      </tbody>
    </table>
    <div class="alert alert-danger" role="alert" *ngIf="!userInfo[0].areas">
      No hay informaci&oacute;n de areas trabajadas.
    </div>
    <table class="table table-striped mt-2" *ngIf="userInfo[0].areas">
      <thead class="table-dark">
        <tr>
          <th>Area</th>
          <th>Duracion</th>
          <th># de sesion</th>
          <th># de cita</th>
          <th>Area completada</th>
        </tr>
      </thead>
      <tbody *ngFor="let area of userInfo[0].areas">
        <tr>
          <th>{{ area.area }}</th>
          <th>{{ area.area_duration }}</th>
          <th>{{ area.area_session }}</th>
          <th>{{ area.area_appnt }}</th>
          <th>{{ area.is_completed ? "Si" : "No" }}</th>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>

<ng-template #paymentsClient let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Informacion de pagos</h5>
    <button class="close" type="button" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row" *ngFor="let info of dataInfo; let idx = index">
      <div class="card mt-3">
        <div class="card-header"> Tratamiento {{ idx + 1 }}</div>
        <div class="card-body">
          <div
            class="row mt-2 mb-2"
            *ngIf="messageDeleted != '' && idx == idxValue"
          >
            <div class="alert alert-success" role="alert">
              {{ messageDeleted }}
            </div>
          </div>
          <div class="row">
            <h5 class="card-title pt-2">
              <strong>
                {{ info.client.name }} - #{{ info.client.record }}
              </strong>
            </h5>
            <p>
              <strong>Tipo de paquete: </strong>{{ info.package.type }} <br />
              <strong>Precio Total: </strong>
              {{ info.package.price | currency }} <br />
              <strong>Apartado: </strong>{{ info.package.advance | currency }}
              <br />
              <strong>Resto a pagar: </strong
              >{{ info.package.remaining | currency }}
              <br />
              <strong>Total pagado: </strong>{{ info.total_paid | currency }}
              <br />
              <strong>Total de sesiones: </strong
              >{{ info.package.numberSessions }} <br />
              <strong>Porcentaje de descuento: </strong>
              {{ info.package.percentage }}% <br />
              <strong>Fecha de venta del paquete: </strong>
              {{ info.package.purchased }} <br />
              <strong> Duraci&oacute;n: </strong> {{ info.package.duration
              }}<br />
            </p>
          </div>
          <div class="row" *ngIf="info.payments.length == 0">
            <div class="alert alert-danger" role="alert">
              No hay pagos registrados en este paquete!
            </div>
          </div>
          <div class="row" *ngIf="info.payments.length != 0">
            <table class="table table striped mt-2">
              <thead class="table-dark">
                <tr>
                  <th>Sesión</th>
                  <th>Pago realizado</th>
                  <th>Metodo de pago</th>
                  <th>Tipo de pago</th>
                  <th>Terminal</th>
                  <th>Comentarios</th>
                  <th>Fecha</th>
                  <th></th>
                </tr>
              </thead>
              <tbody *ngFor="let payment of info.payments">
                <tr>
                  <td>{{ payment.session }}</td>
                  <td>{{ payment.amount | currency }}</td>
                  <td>{{ payment.payment_method }}</td>
                  <td>{{ payment.payment }}</td>
                  <td>{{ payment.terminal }}</td>
                  <td>{{ payment.extra }}</td>
                  <td>{{ payment.filter }}</td>
                  <td>
                    <button
                      type="button"
                      placement="top"
                      ngbTooltip="Eliminar Pago"
                      class="btn btn-danger"
                      (click)="deletePayment(payment.id, idx)"
                    >
                      <i class="bi bi-trash3-fill"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #contractModal let-close="close">
  <div class="modal-header">
    <h4 class="modal-title">Contrato</h4>
    <button class="close" type="button" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <img src="{{ imageContract }}" class="card-img-top" />
  </div>
</ng-template>

<ng-template #paymentsModal let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Pago Adelantado del {{ label }}</h5>
    <button class="close" type="button" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <ngb-alert type="success" *ngIf="message != '' && submitted">
    {{ message }}
  </ngb-alert>
  <div class="modal-body">
    <form [formGroup]="formPayments" (ngSubmit)="createNewPayment()" novalidate>
      <input type="hidden" name="apptId" formControlName="apptId" />
      <div class="mb-3 form-floating">
        <input
          type="text"
          name="amount"
          id="amount"
          formControlName="amount"
          class="form-control"
          placeholder="Cantidad a pagar"
          required
          [ngClass]="{
            'is-invalid': submitted && f.amount.errors,
            'is-valid': submitted && !f.amount.errors
          }"
        />
        <label for="amount">Cantidad a pagar</label>
      </div>
      <div class="form-floating mb-3">
        <select
          name="paymentMethodId"
          id="paymentMethodId"
          class="form-control"
          formControlName="paymentMethodId"
          placeholcer="Metodo de pago"
          (change)="checkPaymentMethods($event)"
          required
          [ngClass]="{
            'is-invalid': submitted && f.paymentMethodId.errors,
            'is-valid': submitted && !f.paymentMethodId.errors
          }"
        >
          <option value="" selected>Seleccione un metodo de pago</option>
          <option
            value="{{ paymentMethod.id }}"
            *ngFor="let paymentMethod of payment_methods"
          >
            {{ paymentMethod.payment_name }}
          </option>
        </select>
        <label for="paymentMethodId">Seleccione un metodo de pago</label>
      </div>
      <div class="form-floating mb-3" *ngIf="paymentsFilter.length > 0">
        <select
          name="paymentId"
          id="paymentId"
          class="form-control"
          formControlName="paymentId"
          placeholcer="Tipo de pago"
          required
          [ngClass]="{
            'is-invalid': submitted && f.paymentId.errors,
            'is-valid': submitted && !f.paymentId.errors
          }"
        >
          <option value="0" selected>Seleccione un tipo de pago</option>
          <option
            value="{{ payment.id }}"
            *ngFor="let payment of paymentsFilter"
          >
            {{ payment.name }}
          </option>
        </select>
        <label for="paymentMethodId">Tipo de pago</label>
      </div>
      <div class="form-floating mb-3" *ngIf="paymentsFilter.length > 0">
        <select
          name="terminal"
          id="terminal"
          class="form-control"
          formControlName="terminal"
          placeholcer="Selecciona una terminal"
          required
          [ngClass]="{
            'is-invalid': submitted && f.terminal.errors,
            'is-valid': submitted && !f.terminal.errors
          }"
        >
          <option value="" selected>Seleccione una terminal</option>
          <option value="Banorte">Banorte</option>
          <option value="BBVA">BBVA</option>
          <option value="Firstdata">Firstdata</option>
          <option value="Netpay">Netpay</option>
          <option value="Getnet">Getnet</option>
        </select>
        <label for="paymentMethodId">Selecciona una terminal</label>
      </div>
      <div class="mb-3 mt-2 d-grip gap-2 d-md-flex justify-content-md-end">
        <button
          type="button"
          class="btn btn-danger"
          (click)="resetAdvancePayments()"
        >
          <i class="bi bi-eraser"></i>
          Cancelar
        </button>
        <button type="submit" class="btn btn-success" [disabled]="submitted">
          <i class="bi bi-plus-circle"></i>
          Agregar pago adelantado
        </button>
      </div>
    </form>
  </div>
</ng-template>
