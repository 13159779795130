import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-show-client',
  templateUrl: './show-client.component.html',
  styleUrls: ['./show-client.component.scss'],
})
export class ShowClientComponent implements OnInit {
  @ViewChild('sessionsInfo', { static: false }) sessionsInfo!: TemplateRef<any>;
  @ViewChild('paymentsClient', { static: false })
  paymentsClient!: TemplateRef<any>;
  @ViewChild('contractModal', { static: false })
  contractModal!: TemplateRef<any>;
  @ViewChild('paymentsModal', { static: false })
  paymentsModal!: TemplateRef<any>;

  formPayments: any = FormGroup;
  modalContractClose: any;
  clientInfo: any = [];
  appointmentInfo: any = [];
  valorationsInfo: any = [];
  sessionsInfoData: any = [];
  breadcrumbs: any = [];
  paramId: string | null = '';
  threatmentNumber: number = 0;
  userInfo: any = [];
  paymentsInfo: any = [];
  submittedAppt: boolean = false;
  message: string = '';
  dataInfo: any = [];
  imageContract: string = '';
  submitted: boolean = false;
  paymentsFilter: any = [];
  payments: any;
  payment_methods: any;
  packageId: number = 0;
  apptId: number = 0;
  label: string = '';
  messageDeleted: string = '';
  idxValue: number = 0;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private modal: NgbModal,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.paramId = this.route.snapshot.paramMap.get('id');
    this.breadcrumbs = [
      {
        name: 'Agenda',
        path: '/dashboard/calendar',
        link: true,
      },
      {
        name: 'Lista de clientes',
        path: '/clients/list',
        link: true,
      },
      {
        name: 'Historico del cliente',
        link: false,
      },
    ];

    this.validate();
    this.getClientInfo();
    this.getPayments();
    this.getPaymentMethods();
  }

  validate() {
    this.formPayments = this.fb.group({
      amount: ['', [Validators.required]],
      paymentMethodId: ['', [Validators.required]],
      terminal: [''],
      paymentId: ['0', [Validators.required]],
      apptId: ['', [Validators.required]],
    });
  }

  get f() {
    return this.formPayments.controls;
  }

  getClientInfo() {
    this.api.getById('clients/appoinments', Number(this.paramId)).subscribe({
      next: (client) => {
        console.log(client.client);
        this.clientInfo = client.client;
        this.appointmentInfo = client.client.appoinments;
        this.valorationsInfo = client.client.valorations;
      },
      error: (err) => {},
    });
  }

  getPayments() {
    this.api.get('payments').subscribe({
      next: (payments) => {
        this.payments = payments.payments;
      },
      error: (err) => {},
    });
  }

  getPaymentMethods() {
    this.api.get('payment_methods').subscribe({
      next: (paymentMethods) => {
        this.payment_methods = paymentMethods.payment_methods;
      },
      error: (err) => {},
    });
  }

  checkPaymentMethods(evt: any): void {
    this.paymentsFilter = this.payments.filter(
      (data: any) => data.payment_method_id === Number(evt.target.value)
    );
  }

  showSessionsInfo(idData: number, num: number): void {
    this.threatmentNumber = num + 1;
    this.userInfo = this.appointmentInfo.filter(
      (data: any) => data.appoinment.id === idData
    );

    console.log('[userInfo]', this.userInfo);
    this.modal.open(this.sessionsInfo, { size: 'lg' });
  }

  closeAppoinmentPackage(id: number): void {
    this.submittedAppt = true;

    this.api.getById('packages/close', Number(id)).subscribe({
      next: (data) => {
        this.message = data.message;
      },
      error: (err) => {},
    });
  }

  showPayments(id: any): void {
    /*     this.api.getById('appoinment/payments', id).subscribe({
      next: (info) => {
        this.dataInfo = info.payments;
 */
    console.log('holas');
    this.paymentsPerClient(id);
    this.modal.open(this.paymentsClient, { size: 'xl' });
    /*       },
      error: (err) => {},
    }); */
  }

  private paymentsPerClient(id: number) {
    console.log('holas');
    this.api.getById('appoinment/payments', id).subscribe({
      next: (info) => {
        this.dataInfo = info.payments;
        //this.modal.open(this.paymentsClient, { size: 'xl' });
      },
      error: (err) => {},
    });
  }

  displayContract(contract: string) {
    let splitData = contract.split('/');
    this.imageContract = `https://www.laserclinic.mx/assets/${splitData[3]}/${splitData[4]}`;
    this.modalContractClose = this.modal.open(this.contractModal, {
      size: 'lg',
    });
  }

  displayAddPayment(threatNumber: number, apptId: number) {
    this.apptId = apptId;
    this.label = `Tratamiento ${threatNumber}`;
    this.formPayments.patchValue({
      apptId: this.apptId,
    });

    this.modalContractClose = this.modal.open(this.paymentsModal, {
      size: 'md',
    });
  }

  createNewPayment(): void {
    this.submitted = true;

    if (this.formPayments.invalid) {
      console.log(this.formPayments);
      this.submitted = false;
      return;
    }

    const obj = {
      paymentMethodId: this.formPayments.value.paymentMethodId,
      terminal: this.formPayments.value.terminal,
      paymentId: this.formPayments.value.paymentId,
      amount: this.formPayments.value.amount,
      apptId: this.formPayments.value.apptId,
      sessionId: 1,
    };

    console.log(obj);
    this.api.create('payments_sessions/advance', obj).subscribe({
      next: (data) => {
        this.message = data.message;
        setTimeout(() => {
          this.resetAdvancePayments();
          this.submitted = false;
          this.message = '';
          location.reload();
        }, 3000);
      },
      error: (err) => {},
    });
  }

  deletePayment(idPayment: number, threatment: number): void {
    this.idxValue = threatment;
    this.api.delete('payments_sessions', idPayment).subscribe({
      next: (data) => {
        this.messageDeleted = data.message;
        console.log(this.messageDeleted);
        this.paymentsPerClient(data.data.clientId);

        setTimeout(() => {
          this.messageDeleted = '';
        }, 3000);
      },
      error: (err) => {},
    });
  }

  resetAdvancePayments() {
    this.formPayments.get('amount').setValue('');
    this.formPayments.get('paymentMethodId').setValue('');
    this.formPayments.get('paymentId').setValue(0);
    this.formPayments.get('terminal').setValue('');
    this.paymentsFilter = [];
    this.submitted = false;
    this.close();
  }

  close(): void {
    this.modalContractClose.close();
  }
}
